
import _ from 'lodash';
import Vue from 'vue';
import { Feature, MapBrowserEvent } from 'ol';
import { defaults as defaultMapControls, FullScreen, Attribution } from 'ol/control';
import { FeatureLike } from 'ol/Feature';
import { Polygon } from 'ol/geom';
import { Style } from 'ol/style';
import { pointerMove } from 'ol/events/condition';
import { default as VueLayers } from 'vuelayers';
import { Component, Prop, Watch, Emit, Inject } from 'vue-property-decorator';
import { Mosaic, Bounds, ProductDetails } from '@/lib/mv-web';
import { extendCoordinates } from 'ol/extent';
import defaultStyles from '@/lib/ol-custom-ext/DefaultMapStyles';
import basemaps from '@/lib/ol-custom-ext/basemaps';
import WatermarkControl from '@/lib/ol-custom-ext/WatermarkControl';
import { SET_CURRENT } from '@/store/mutation-types';

Vue.use(VueLayers);

type SymbolizerFactory = () => (feature: any, resolution: number) => any;

@Component({
})
export default class IndexMap extends Vue {
  @Prop({default: true})
  public showUnauthorized!: boolean;

  @Prop({ default: 2 })
  public initialZoom!: number;

  @Prop({ default: null })
  public baseUrl!: string | null;

  public zoom: number = 2;

  protected selectedGuid: string | null = null;

  protected get mosaics(): Mosaic[] {
    return this.$store.getters.filteredMosaics;
  }

  public mounted() {
    this.zoom = this.initialZoom;
  }

  public get rootUrl(): string {
    let baseUrl = this.baseUrl;
    if (!baseUrl) {
      baseUrl = `${this.$store.state.apiUrls.mapvault}`;
    }
    return baseUrl;
  }

  protected get basemap() { return basemaps[this.$store.state.basemap]; }

  protected get mapControls() {
    return defaultMapControls().extend([
      new Attribution({collapsed: true}),
      new FullScreen(),
      new WatermarkControl({}),
    ]);
  }

  protected get selectCondition() { return pointerMove; }

  protected get items() {
    if (!this.mosaics) {
      return [];
    }
    const mms = this.mosaics
      .map( (m) => ({
        properties: this.getProperties(m),
        coordinates: m.extent ? this.extentToCoordinates(m.extent) : undefined,
      }),
    ).sort( (one, another) => {
        if (!one.coordinates) { return 1; }
        if (!another.coordinates) { return -1; }
        return -( (new Polygon(one.coordinates)).getArea() - (new Polygon(another.coordinates)).getArea());
      });
    return mms.filter( (m) => !!m.coordinates);
  }

  protected mosaicFootprintSymbolizer = ( feature: Feature) => {
    try {
      if (feature.get('authorized') !== true) {
        if (!this.showUnauthorized) {
          return null;
        }
        if (feature.get('guid') === this.getCurrentHighlightedFootprint()) {
          return defaultStyles.dulledOverlay;
        }
        return defaultStyles.dulled;
      }
      if (feature.get('guid') === this.getCurrentHighlightedFootprint()) {
        return defaultStyles.overlay;
      }
      return defaultStyles.standard;
    } catch (e) {
      // If there is any problem, fail over to returning null
    }
    return null;
  }
  protected getCurrentHighlightedFootprint() { return this.selectedGuid; }

  protected getProperties(item: Mosaic) {
    const props: any = _.pickBy(item, (v, k) => k !== 'extent');
    const details = this.$store.state.details.find( (d: ProductDetails) => d.guid === item.guid);
    if (!!details) {
      props.authorized = details.authorized;
    }
    return props;
  }

  protected extentToCoordinates(extent: Bounds) {
    const w = extent.xMax - extent.xMin;
    const h = extent.yMax - extent.yMin;
    if (w < 359 || h < 120) {
      const m = extent.xMax + extent.xMin;
      if (m > 360) {
          // midpoint past the anti-meridian; warp on earth westward
          extent.xMin -= 360;
          extent.xMax -= 360;
      }
      if (m < -360) {
          // midpoint past the anti-meridian; warp on earth eastward
          extent.xMin += 360;
          extent.xMax += 360;
      }
      return [[
          [extent.xMin, extent.yMin],
          [extent.xMax, extent.yMin],
          [extent.xMax, extent.yMax],
          [extent.xMin, extent.yMax],
          [extent.xMin, extent.yMin],
      ]];
    }
    return null;
  }

  private onPointerMove(evt: MapBrowserEvent) {
    try {
      // the "pixel" property does not work, but the "pixel_" does; there may be an error in the type registration
      const candidates = evt.map.getFeaturesAtPixel((evt as any).pixel_, { checkWrapped: true });
      if (candidates.length === 0) {
        this.selectedGuid = null;
        return;
      }
      const topCandidate = candidates.sort((one: FeatureLike, another: FeatureLike) => {
        const gOne = one.getGeometry() as Polygon;
        if (gOne === null) {
          return 1;
        }
        const gAnother = another.getGeometry() as Polygon;
        if (gAnother === null) {
          return -1;
        }
        return gOne.getArea() - gAnother.getArea();
      })[0];

      this.selectedGuid = topCandidate.get('guid');
    } catch (TypeError) {
      // These occur upon first entry into the window; just swallow them.
    }
  }

  // watch the property to keep the onPointerMove handler simple
  @Watch('selectedGuid')
  private onHighlighted( current: string|null, previous: string|null) {
    if (!_.isEqual(current, previous)) {
      // triggers a redraw of the layer; the symbolizer will provide new styles
      (this.$refs.mosaicFootprints as any).$layer.changed();
    }
  }

  private onClick(evt: MapBrowserEvent) {
    // if the user clicks, they must have moved to the feature of interest first,
    // so the pointer move will have acquired the guid of interest
    if (this.selectedGuid !== null) {
      this.$store.commit(SET_CURRENT, this.selectedGuid);
    }
  }
}
